import React from "react";

import { Container, Grid, Box, Link, Typography, Button } from "@mui/material"; // Example with Material-UI
const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        // backgroundColor: "#f8f9fa",
        backgroundColor: "#1976d2",
        padding: "2rem 0",
        marginTop: "auto",
        textAlign: "center",
        borderTop: "1px solid #ddd",
        color: "#fff",
      }}
    >
      <Container>
        <Grid container spacing={4}>
          {/* Left Column: Text/Info */}
          <Grid item xs={12} md={3}>
            <Box mt={2}>
              <h4 color="inherit" sx={{ margin: "0 1rem" }}>
                Company
              </h4>
              <p>Focus PLC, Seid Yasin Building, 5th Floor Dessie</p>
            </Box>
          </Grid>

          {/* Right Column: Form */}
          <Grid item xs={12} md={3}>
            <h3>Sitemap </h3>
            <Box mt={2}>
              <Typography
                href="/home"
                color="inherit"
                sx={{ margin: "0 1rem" }}
              >
                Home
              </Typography>
              <Typography
                href="/home"
                color="inherit"
                sx={{ margin: "0 1rem" }}
              >
                About Us
              </Typography>
              <Typography
                href="/terms"
                color="inherit"
                sx={{ margin: "0 1rem" }}
              >
                Services
              </Typography>

              <Typography
                href="/privacy"
                color="inherit"
                sx={{ margin: "0 1rem" }}
              >
                Projects
              </Typography>
            </Box>
          </Grid>
          {/* Right Column: Form */}
          <Grid item xs={12} md={3}>
            <h3>Contact </h3>
            <Box mt={2}>
              <Typography color="inherit" sx={{ margin: "0 1rem" }}>
                Email: info@focus.com
              </Typography>
              <Typography
                href="/privacy"
                color="inherit"
                sx={{ margin: "0 1rem" }}
              >
                Mob: +251 91 190 0138
              </Typography>
            </Box>
          </Grid>

          {/* Right Column: Form */}
          <Grid item xs={12} md={3}>
            <h3>Links </h3>
            <Box mt={2}>
              <Typography
                href="/terms"
                color="inherit"
                sx={{ margin: "0 1rem" }}
              >
                Terms of Service
              </Typography>
              <Typography
                href="/privacy"
                color="inherit"
                sx={{ margin: "0 1rem" }}
              >
                Privacy Policy
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body2" color="text.secondary">
          &copy; {new Date().getFullYear()} focus.com All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
