import axios from "axios";

const api = axios.create({
  //baseURL: "http://192.168.3.8:8000/api",
  baseURL: "https://focus.qmindpro.com/api", // Production URL
  withCredentials: true, // Send cookies (credentials) with the request
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
