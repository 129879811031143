import { useState } from "react";
import { Card, CardContent, Typography, Avatar, IconButton, Box } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const testimonials = [
  {
    name: "Client Name",
    role: "CEO, XYZ Corp",
    text: "Focus provided us with valuable insights and helped streamline our operations, resulting in increased efficiency and profits.",
    image: "./profile.png",
  },
  {
    name: "Seada Abera",
    role: "Manager, Smart Technologies & Promotion",
    text: "Their team’s expertise in digital transformation helped us adapt to changing market demands and stay competitive.",
    image: "./seada_abera.jpeg",
  },
  {
    name: "Salahadin Seid",
    role: "Manager, QMindPro",
    text: "Highly recommended! It made our workflow much smoother.",
    image: "./salahadin.jpeg",
  },
];

export default function TestimonialCarousel() {
  const [index, setIndex] = useState(0);

  const nextTestimonial = () => {
    setIndex((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ maxWidth: 400, margin: "auto" }}>
      <Card sx={{ p: 3, textAlign: "center", boxShadow: 3, borderRadius: 3 }}>
        <Avatar src={testimonials[index].image} sx={{ width: 80, height: 80, margin: "auto" }} />
        <CardContent>
          <Typography variant="h6" fontWeight="bold">{testimonials[index].name}</Typography>
          <Typography variant="body2" color="text.secondary">{testimonials[index].role}</Typography>
          <Typography variant="body1" mt={2}>{testimonials[index].text}</Typography>
        </CardContent>
      </Card>
      
      <Box mt={2} display="flex" justifyContent="space-between" width="100px">
        <IconButton onClick={prevTestimonial} color="primary">
          <ArrowBack />
        </IconButton>
        <IconButton onClick={nextTestimonial} color="primary">
          <ArrowForward />
        </IconButton>
      </Box>
    </Box>
  );
}
