import React, { useState } from "react";

import {
  Box,
  TextField,
  Container,
  Grid,
  Typography,
  Button,
} from "@mui/material"; // Example with Material-UI
import Header from "../components/Header";
import Footer from "../components/Footer";
const Dashboard = () => {
  const [formData, setFormData] = useState({
    name: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
    // Add your form submission logic here
  };
  return (
    <div>
      <Header />
      <Container style={{ marginTop: "2rem" }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          Contact us directly or fill out the form and we will get back to you
          promptly.
        </Typography>
        {/* Responsive Two-Column Grid */}
        <Grid container spacing={4}>
          {/* Left Column: Text/Info */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Our Office
            </Typography>
            <Typography variant="body2" paragraph>
              Piassa Seid Yasin Building, Dessie, Ethiopia
            </Typography>
            <Typography variant="body2" paragraph>
              Phone: (251) 91 190 0138
            </Typography>
            <Typography variant="body2" paragraph>
              Email: contact@focus.com
            </Typography>
          </Grid>

          {/* Right Column: Form */}
          <Grid item xs={12} md={6}>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
              onSubmit={handleSubmit}
            >
              <TextField
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
              />
              <TextField
                label="Email"
                name="email"
                value={formData.email || ""}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
              />
              <TextField
                label="Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                required
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default Dashboard;
