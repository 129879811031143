import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Button,
} from "@mui/material"; // Example with Material-UI
import { Link } from "react-router-dom";
const Services = () => {
  return (
    <div>
      <Header />
      <Container style={{ marginTop: "2rem" }}>
        <Box
          sx={{
            textAlign: "center",
            padding: "4rem 2rem",
            backgroundColor: "#f8f9fa",
          }}
        >
          {/* Header */}
          <Typography variant="h3" sx={{ marginBottom: "1rem" }}>
          Services
          </Typography>
          <Typography
            variant="h6"
            color="textSecondary"
            sx={{ marginBottom: "2rem" }}
          >
            FOCUS TRADING PLC has been involved in agricultural and 
            development related projects production, marketing and 
            consumption process and research, train and advisory consulting. 
            It will expand its business areas in its future expansion, like construction and road.
          </Typography>

          <Typography
            variant="h6"
            color="textSecondary"
            sx={{ marginBottom: "2rem" }}
          >
           
          </Typography>

          {/* Pricing Cards */}
          <Grid container spacing={3} justifyContent="center">
            {/* Basic Plan */}
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ border: "1px solid #ddd", borderRadius: "8px" }}>
                <CardContent>
                  <Typography
                    variant="h4"
                    sx={{ marginBottom: "1rem", color: "#1976d2" }}
                  >
                    Training
                  </Typography>
                 
                  
                 
                  <Typography
                    color="textSecondary"
                    sx={{ marginBottom: "1.5rem" }}
                  >
                    
              Graduate program in department of Economics, Agricultural
              Economics, Research methods for
              socio-economists, macroeconomics, microeconomics, production
              economics and marketing and price analysis, project planning,
              identification and analysis, econometrics, gender and development
         
              Undergraduate program: Microeconomics, macroeconomics, finance,
              Farm management, agricultural Project planning and analysis,
              Economics and econometrics
            
                  </Typography>
                  
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: "1rem",
                      backgroundColor: "#1976d2",
                      color: "#fff",
                    }}
                  >
                    <Link
                      to="/signup"
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Contact Us
                    </Link>
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* Pro Plan */}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  border: "2px solid #1976d2",
                  borderRadius: "8px",
                  backgroundColor: "#e3f2fd",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h4"
                    sx={{ marginBottom: "1rem", color: "#1976d2" }}
                  >
                    Research and Advise
                  </Typography>
               
                  <Typography
                    color="textSecondary"
                    sx={{ marginBottom: "1.5rem" }}
                  >
                    Advisor More Than 65 MSc students; Advisor More Than ten PhD Students More Than 35 MSc students internalexaminer; More Than 20 MSc students External examiner
                  </Typography>
                  <ul style={{ textAlign: "left", padding: "0 1rem" }}>
                    <li>Prepare technical proposal</li>
                    <li>Design research methodology</li>
                    <li>Design data collection tools</li>
                    <li>Supervise, monitor and Conduct big data collection</li>
                    <li>Conduct data analysis using excel, STATA, R and SPSS</li>
                    <li>Report writing and presentation</li>
                    
    
    
                  </ul>
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: "1rem",
                      backgroundColor: "#1976d2",
                      color: "#fff",
                    }}
                  >
                    <Link
                      to="/signup"
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Contact Us
                    </Link>
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* Enterprise Plan */}
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ border: "1px solid #ddd", borderRadius: "8px" }}>
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{ marginBottom: "1rem", color: "#1976d2" }}
                  >
                    Technical notes & module preparation (Books)
                  </Typography>
                
                  <Typography
                    color="textSecondary"
                    sx={{ marginBottom: "1.5rem" }}
                  >
                    Books 
                  </Typography>
                  <ul style={{ textAlign: "left", padding: "0 1rem" }}>
                <li>
                  Agricultural Project planning and
                  analysis,  2014
                </li>
                <li>Economics of Farm Management, 2020 </li>
              </ul>
                  
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: "1rem",
                      backgroundColor: "#1976d2",
                      color: "#fff",
                    }}
                  >
                    <Link
                      to="/contactUs"
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Contact Us
                    </Link>
                  </Button>
                </CardContent>
              </Card>
            </Grid>
             {/* Enterprise Plan */}
             <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ border: "1px solid #ddd", borderRadius: "8px" }}>
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{ marginBottom: "1rem", color: "#1976d2" }}
                  >
                  Consulting
                  </Typography>
                
                  <Typography
                    color="textSecondary"
                    sx={{ marginBottom: "1.5rem" }}
                  >
                   Deliver expert guidance and solutions across diverse domains— such as technology, education, business, and research—empowering clients to achieve their goals through innovative strategies, tailored insights, and actionable plans.
                  </Typography>
                  
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: "1rem",
                      backgroundColor: "#1976d2",
                      color: "#fff",
                    }}
                  >
                    <Link
                      to="/contactUs"
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Contact Us
                    </Link>
                  </Button>
                </CardContent>
              </Card>
            </Grid>
             {/* Enterprise Plan */}
             <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ border: "1px solid #ddd", borderRadius: "8px" }}>
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{ marginBottom: "1rem", color: "#1976d2" }}
                  >
                    R&D
                  </Typography>
                
                  <Typography
                    color="textSecondary"
                    sx={{ marginBottom: "1.5rem" }}
                  >
                   Support business organizations, start-ups, entrepreneurs, and high-tech companies in international expansion planning, joint ventures, joint grant writing, joint research on digital innovation and transformation, alliances, and other cooperation methods.
                  </Typography>
                  
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: "1rem",
                      backgroundColor: "#1976d2",
                      color: "#fff",
                    }}
                  >
                    <Link
                      to="/contactUs"
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Contact Us
                    </Link>
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        <div style={{ paddingTop: 30 }}></div>
      </Container>
      
      <Footer />
    </div>
  );
};

export default Services;
